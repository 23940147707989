<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色权限</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-row>
        <el-button type="primary"
                   class="addbtn"
                   @click="showAddDialog">添加角色</el-button>
      </el-row>
      <!-- 视图列表区 -->
      <el-table :data="roleList"
                stripe
                style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row :class="[i1 === 0 ? '' : 'bdtop']"
                    v-for="(item1, i1) in scope.row.children"
                    :key="item1.id"
                    type="flex"
                    justify="space-between">
              <!-- 渲染一级权限 -->
              <el-col :span="4"
                      :offset="6">
                <el-tag>{{item1.auth_name}}</el-tag>
                <i class="el-icon-caret-right"></i>
              </el-col>
              <!-- 渲染二级权限 -->
              <el-col :span="14">
                <el-row>
                  <el-col :span="4"
                          v-for="(item2) in item1.children"
                          :key="item2.id">
                    <el-tag type="success">{{item2.auth_name}}</el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column type="index"
                         label="#"
                         width="100"></el-table-column>
        <el-table-column prop="role_name"
                         label="角色名称"></el-table-column>
        <el-table-column prop="desc"
                         label="角色描述"></el-table-column>
        <el-table-column label="操作"
                         width="300">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       @click="deleteById(scope.row.id)">删除</el-button>
            <el-button type="danger"
                       icon="el-icon-setting"
                       size="mini"
                       @click="showSetAuthDialog(scope.row)">分配权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加编辑表单 -->
    <el-dialog :title="titleMap[dialogTitle]"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="dateForm"
               :rules="dateFormRules"
               ref="dateFormRef"
               label-position="top">
        <el-form-item label="角色名称："
                      prop="role_name">
          <el-input v-model="dateForm.role_name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述："
                      prop="desc">
          <el-input type="textarea"
                    :rows="3"
                    resize="none"
                    v-model="dateForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 权限分配 -->
    <el-dialog title="权限分配"
               :visible.sync="setAuthDialogVisible"
               width="50%"
               :close-on-click-modal="false"
               @close="setAuthDialogClose">
      <el-tree :data="authList"
               :props="treeProps"
               ref="treeRef"
               show-checkbox
               node-key="id"
               default-expand-all
               :default-checked-keys="defKeys">
      </el-tree>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="setAuthDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="allorRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      roleList: [],
      authList: [],
      titleMap: {
        addData: '添加角色',
        updateData: '修改角色信息'
      },
      dialogTitle: '',

      dialogVisible: false,
      setAuthDialogVisible: false,

      treeProps: {
        label: 'auth_name',
        children: 'children'
      },
      // 默认选中id的树结点
      defKeys: [],
      role: '',

      // 表单
      dateForm: {
        role_name: '',
        desc: ''
      },

      // 表单验证规则
      dateFormRules: {
        role_name: [
          { required: true, message: '请输入角色组名称', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入角色组简述', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    async getRoleList () {
      const { data: res } = await this.$http.get('roles')
      if (res.code !== 200 && res.msg !== 'success') {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.roleList = res.data
    },

    // 删除该ID信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这个角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('roles/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getRoleList()
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.dateFormRef.resetFields()
    },

    // 权限分配
    async showSetAuthDialog (role) {
      this.roleId = role.id
      const { data: res } = await this.$http.get('auths')
      if (res.code !== 200 && res.msg !== 'success') {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.authList = res.data
      // 递归获取三级节点
      this.getLeafKeys(role, this.defKeys)
      this.setAuthDialogVisible = true
    },

    // 通过递归方式获取当前选中树节点
    getLeafKeys (node, arr) {
      node.children.forEach(item => {
        return arr.push(item.id)
      })
    },

    // 关闭对话框清除数据
    setAuthDialogClose () {
      this.defKeys = []
    },

    async allorRole () {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      const idSrt = keys.join(',')

      const { data: res } = await this.$http.put(`roles/${this.roleId}?role_auth_ids=${idSrt}`)
      if (res.code !== 200) {
        return this.$message.error('分配权限失败！')
      }
      this.$message.success('分配权限成功！')

      this.setAuthDialogVisible = false
      this.getRoleList()
    },

    // 添加按钮
    showAddDialog () {
      this.dialogVisible = true
      this.dialogTitle = 'addData'
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('roles/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.dialogTitle = 'updateData'
      this.dialogVisible = true
    },

    upDate () {
      // 添加请求
      if (this.dialogTitle === 'addData') {
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          // 如果正确发起添加请求
          const { data: res } = await this.$http.post('roles', this.dateForm)
          if (res.code !== 200) {
            this.$message.error('网络延时，请重新刷新网页重试...')
          }
          this.$message.success('添加成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getRoleList()
        })
      } else {
        // 修改请求
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put('roles/' + this.dateForm.id, {
            role_name: this.dateForm.role_name,
            desc: this.dateForm.desc
          })
          if (res.code !== 200) {
            return this.$message.error('网络延时，请刷新页面重试...')
          }
          this.$message.success('修改成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getRoleList()
        })
      }
    }

  },

  created () {
    this.getRoleList()
  }
}
</script>

<style scoped>
.el-tag {
  margin: 7px;
}

.bdtop {
  border-top: 1px solid #eee;
}
</style>
